import styled, { css } from 'styled-components';

export const justifyCenter = css`
  &.justify-center {
    justify-content: center;
  }
`;

export const alignCenter = `
  &.align-center {
    align-items: center;
  }
`;

const cssColumnReverse = css`
  display: flex;
  flex-direction: column-reverse;
`;

const cssFlexWrap = css`
  flex-wrap: wrap;
  flex-grow: 1;
`;

const cssColumn = css`
  display: flex;
  flex-direction: column;
`;

const cssRow = css`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  ${cssColumn}
  ${justifyCenter}
  ${alignCenter}
  &.gap-4 {
    gap: 4px !important;
  }
`;

export const Row = styled.div`
  ${cssRow}
  ${(props) => (props.shouldWrap ? cssFlexWrap : '')}
  @media (max-width: 768px) {
    ${(props) => (props.shouldBreak ? cssColumn : '')}
    ${(props) => (props.shouldBreakReverse ? cssColumnReverse : '')}
  }
  ${justifyCenter}
  ${alignCenter}
`;

export const Icon = styled.i`
  margin-left: 8px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  background-position-x: left;
  background-image: url('/assets/icons/${(props) => props.name}.svg');
`;

export const MediumText = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #080e14;
  span {
    color: #4ebfee;
  }
`;

export const NormalText = styled.div`
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #080e14;
`;

export const SubText = styled.div`
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 14px;
  color: rgba(8, 14, 20, 0.6);
`;

export const Tag = styled(Row)`
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  background: #f5fafd;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  margin-right: 8px;
  color: #4ebfee;
  max-height: 24px;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    margin: 0.5rem;
  }
`;

export const Dot = styled.div`
  background: #4ebfee;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  min-width: 5px;
  min-height: 5px;
  display: block;
  margin: 0 1.25rem;
  @media only screen and (max-width: 768px) {
    &.article {
      display: block;
    }
    display: none;
  }
`;

export const navigateTo = (url, target) => {
  if (isWindow())
    if (target) window.open(url, target);
    else window.location.href = url;
};

export const isWindow = () => typeof window !== `undefined`;
